import { ExtentChangeResult, MapAdaptor, SelectResult } from './../../../services/util/MapAdaptor';
import { DialogService } from 'aurelia-dialog';
import { Paginated } from '@feathersjs/feathers';
import { autoinject } from 'aurelia-dependency-injection';
import { bindable, LogManager, PLATFORM } from 'aurelia-framework';

import { FILE_TYPES } from '@wsb_dev/datafi-shared/lib/types/FileTypes';
import { APIFile } from '@wsb_dev/datafi-shared/lib/types/File';
import { ExecuteJobData, SaveFileActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import { fileFilters, ValidatedProjectFile } from '../../../types/ProjectFile';
import { ProjectFileEdit } from './../project-files-edit/project-file-edit';

import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { ActionService } from '../../../services/actions/ActionService';

import { MdcUploadDialog } from '../../../components/mdc-upload-dialog/mdc-upload-dialog';

import { subscribe } from '../../../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../../../util/events/eventAggregatorWrapper';
import { SelectionChange } from '../../../components/mdc-file-gallery/events/SelectionChange';

import { FilterDialog } from '../../../components/filter-dialog/filter-dialog';
import { Filter } from '@wsb_dev/datafi-shared/lib/types/Filter';
import { Project } from '@wsb_dev/datafi-shared/lib/types';
import { filtersToQuery } from '@wsb_dev/datafi-shared/lib/util/fields/filtersToQuery';
import { FileGalleryViewType } from '../../../components/mdc-file-gallery/mdc-file-gallery';
import { BrowserStorage } from '../../../services/util/BrowserStorage';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { paginate } from '@wsb_dev/datafi-shared/lib/util/objects/paginate';
import { FileFolder } from '@wsb_dev/datafi-shared/lib/types/FileFolder';
import { AppConfig } from '../../../services/util/AppConfig';
import { DFPUrlObj } from '../../ol-map/sources/dfpSourceUrlGeojson';
import Cluster from 'ol/source/Cluster';
import VectorLayer from 'ol/layer/Vector';
import { generatePath, IGeneratePath } from '@wsb_dev/datafi-shared/lib/util/files/generatePath';
import { IUploadActivateParams } from '../../../components/mdc-upload-dialog/mdc-upload-dialog';

PLATFORM.moduleName('../../mdc-upload-dialog/mdc-upload-dialog');
PLATFORM.moduleName('../project-files-edit/project-file-edit');

const log = LogManager.getLogger('dfp:project-files');

const PHOTO_LAYER_ID = 'photoFeatures';

const params = {
    $modify: 'toGeoJSON',
};

@subscribe({
    events: [
        { eventEmitter: 'ea', event: SelectionChange, fn: 'updateMapSelection' },
        { event: MapAdaptor.EVENT_SELECT, eventEmitter: 'ma', fn: 'selectMapFile' },
        { event: 'mapattached', eventEmitter: 'ma', fn: 'initializeMap' },
    ],
})
@subscribe({
    attached: 'mapAttached',
    detached: 'mapDetached',
    events: [
        { eventEmitter: 'ma', event: 'extentchange', fn: 'onExtentChange' },
    ],
})
@autoinject

export class ProjectFiles {
    @bindable params: Record<string, any>;

    @bindable project: Project;
    projectChanged() {
        if (!this.params) {
            this.params = {};
        }
        this.params.project_id = this.project?.id;
        this.params.program_folder = this.selectedFolder?.value ? this.selectedFolder.value : 'root';
    }

    @bindable files: ValidatedProjectFile[];
    @bindable filterMap: boolean;
    @bindable selectedView: FileGalleryViewType = 'list';
    @bindable selectedFileType = 'all';
    selectedFiles: ValidatedProjectFile[];

    @bindable filesTotal = 0;
    @bindable pageNumber = 1;
    @bindable perPage = 10;
    downloadPending: boolean;
    pending: boolean;
    refreshTimeout: any;

    @bindable filterDialogResults: number;

    @bindable tags: '';
    @bindable folders: FileFolder[];
    @bindable selectedFolder: FileFolder;
    moveToFolder: string;

    filterFields: Filter[] = fileFilters;

    constructor(
        private api: DatafiProAPI,
        private program: ActiveProgram,
        private actionService: ActionService,
        private dialogs: DialogService,
        private ea: EventAggregatorWrapper,
        private ma: MapAdaptor,
        private storage: BrowserStorage,
        private config: AppConfig,
    ) {
    }

    async attached() {
        this.files = [];
        this.filterDialogResults = 0;
        this.program.load().then(async () => {
            this.folders = this.program.fileFolders;
            this.params = {
                ...this.params,
                program_id: this.program.id,
                project_id: this.project?.id,
                program_folder: 'root',
            };

            // if the map is already created
            // we need to initialize it manually
            // since the event will never be dispatched for mapattached
            if (this.ma.map) {
                await this.initializeMap();
            }

            await this.updateProjectsFiles();
        });

        // catch error if not found
        await this.storage.get('project-files:selectedView')
            .then((result) => {
                const view = result.data?.selectedView as FileGalleryViewType;
                if (view === 'gallery' || view === 'list') {
                    this.selectedView = result.data.selectedView;
                }
            }).catch((e) => log.debug(e));
    }

    detached(): void {
        this.selectedFolder = null;
        this.params = {};
        this.destroyFiles();
        this.storage.create({
            id: 'project-files:selectedView',
            data: { selectedView: this.selectedView },
        });
    }

    onExtentChange = (eventData: ExtentChangeResult) => {
        this.params = {
            ...this.params,
            $modify: {
                toGeoJSON: [],
                intersect: [eventData.bbox],
            },
        };
        this.updateProjectsFiles();
    }

    selectMapFile = (eventData: SelectResult) => {
        const props = [];
        const selection = eventData.target[0];

        if (selection.layerId !== PHOTO_LAYER_ID) {
            return;
        }

        if (selection['properties']?.features) {
            const pointSelection = selection['properties'].features;
            pointSelection.forEach((element) => {
                props.push(element.getProperties().id);
            });
        }

        this.files.forEach((file: ValidatedProjectFile) => {
            if (props.includes(file.id) || file.id === selection['properties']?.id) {
                file.__selected = true;
            }
        });

        this.updateSelectedFiles();
        this.updateMapSelection();
    }

    /**
     * Normally called by event when map is created.
     */
    async initializeMap() {
        const layer = await this.ma.getVectorLayer({
            layerId: PHOTO_LAYER_ID,
            createLayer: true,
            updateLayer: true,
            layerOptions: {
                dfpReady: true,
                visible: true,
                style: {
                    type: 'style/photoStyle',
                },
                source: {
                    type: 'ol/source/Cluster',
                    source: {
                        type: 'dfp/source/VectorDFP',
                        url: {
                            type: 'url/dfp/GeoJSON',
                            serviceType: 'projects-files',
                            baseUrl: this.config.API_HOST,
                            programId: this.program.id,
                            typeId: 0,
                        },
                    },
                },
            },
        });

        layer.setVisible(true);
    }

    async refreshMap(query: Record<string, any>) {

        const layer = await this.ma.getVectorLayer({ layerId: PHOTO_LAYER_ID }) as VectorLayer<Cluster>;
        // get cluster source then vector source
        try {
            let source: any = layer?.getSource();
            if (source.getSource) {
                source = source.getSource();
            }
            const url = source?.getUrl() as DFPUrlObj;
            url?.setParams({ query }, layer);
        } catch (e) {
            log.warn(e);
        }
    }

    mapAttached() {
        return;
    }

    mapDetached() {
        this.params = {
            ...this.params,
            $modify: undefined,
        };
        this.updateProjectsFiles();
    }

    filterMapChanged(filterMap: boolean): void {
        this.onExtentChange(this.ma.getExtent());
        if (filterMap) {
            this.mapAttached();
        } else {
            this.mapDetached();
        }
    }

    async filesChanged(newFiles: ValidatedProjectFile[], oldFiles: ValidatedProjectFile[]): Promise<void> {
        if (oldFiles?.length) {
            oldFiles.forEach((f) => f.destroy());
        }

    }

    perPageChanged(perpage: number): void {
        this.params = {
            ...this.params,
            ...params,
            $limit: perpage,
            $skip: (this.pageNumber - 1) * this.perPage,
        };
        this.updateProjectsFiles();
    }

    pageNumberChanged(pageNumber: number) {
        this.params = {
            ...this.params,
            ...params,
            $skip: (this.pageNumber - 1) * this.perPage,
        };

        this.updateProjectsFiles();
    }

    async updateProjectsFiles() {
        this.pending = true;
        const query = {
            program_id: this.program.id,
            program_folder: this.selectedFolder?.value ? this.selectedFolder.value : 'root',
            ...params,
            ...this.params,
        };
        this.refreshMap(query);
        this.api.projectFiles.find({ query })
            .then((result: Paginated<ValidatedProjectFile>) => {
                this.files = result.data.map((f) => new ValidatedProjectFile(f, this.api.files));
                this.filesTotal = result.total;
                this.pending = false;
                this.fetchThumbnails(this.files);
            });
    }

    destroyFiles() {
        this.selectedFiles = [];
        this.files?.forEach((f) => {
            f.destroy();
        });
        this.files = [];
    }

    fetchThumbnails(files: ValidatedProjectFile[], retryIn?: number, currentIteration?: number) {
        if (!retryIn) {
            retryIn = 1000;
        }

        if (this.refreshTimeout) {
            window.clearTimeout(this.refreshTimeout);
            this.refreshTimeout = null;
        }

        currentIteration = currentIteration || 1;
        if (currentIteration > 5) {
            return;
        }
        const retries = [];
        files.forEach((item) => {
            if (item.file_type === 'image') {

                // skip if we already have a thumb
                if (item._blobs.thumbnail) {
                    return;
                }

                if (item.thumbnail_id) {
                    item.getThumbnail();
                } else {
                    retries.push(item);
                }
            }
        });

        if (retries.length && retryIn) {
            retries.sort((a, b) => a.id > b.id ? 1 : a.id < b.id ? -1 : 0);
            this.refreshTimeout = setTimeout(() => {
                this.api.projectFiles.find({
                    query: {
                        ...this.params,
                        id: { $in: retries.map((p) => p.id) },
                        $select: ['thumbnail_id', 'id', 'geom'],
                        $sort: { id: 1 },
                    },
                }).then((result: Paginated<ValidatedProjectFile>) => {
                    result.data.forEach((item, index) => {
                        Object.assign(retries[index], item);
                    });
                    return this.fetchThumbnails(retries, Math.round(retryIn * 1.5), currentIteration + 1);
                });
            }, retryIn);
        }
    }

    uploadFiles() {
        const userOrgId = this.api.auth.me.organizationId;
        this.dialogs.open({
            viewModel: MdcUploadDialog,
            model: {
                folders: this.folders,
                getFileName: (file: File) => {
                    const folder = this.selectedFolder?.value ? `${this.selectedFolder.value}/` : '';
                    return generatePath({
                        programId: this.program.id,
                        managementOrgId: this.program.managementOrganizationId,
                        userOrgId: userOrgId,
                        projectId: this.project?.id,
                        folder: folder,
                        fileName: file.name,
                        name: 'project-files',
                    } as IGeneratePath);
                },
            } as IUploadActivateParams,
        }).whenClosed((result) => {
            if (!result.output[0].length) {
                return;
            }
            const newFiles = result.output[0].map((item: APIFile): ValidatedProjectFile => {
                const type = FILE_TYPES.find((type) => type.re.test(item.id));
                const folder = this.selectedFolder?.value ? this.selectedFolder.value : 'root';

                if (result.output[1]) {
                    this.tags = result.output[1].toString();
                }
                return new ValidatedProjectFile({
                    file_id: item.id,
                    project_id: this.params.project_id,
                    program_id: this.program.id,
                    file_type: type ? type.type : 'unknown',
                    file_name: item.name || 'Untitled File',
                    user_tags: this.tags,
                    program_folder: folder,
                }, this.api.files);
            });
            const pages = paginate(newFiles, 10);
            pages.forEach((page) => {
                this.api.projectFiles.create(page).then((projectFiles: ValidatedProjectFile) => {
                    projectFiles = projectFiles
                        .map((f) => new ValidatedProjectFile(f, this.api.files));
                    this.files = this.files.concat(projectFiles);
                    this.fetchThumbnails(this.files);
                });
            });
        });
    }

    updateFilters() {
        return this.dialogs.open({
            viewModel: FilterDialog,
            model: {
                filterProperties: this.filterFields,
                currentFilter: this.params,
                sourceObjects: this.files,
            },
        }).whenClosed((result) => {
            if (result.wasCancelled) return;
            if (result.output) {
                this.selectedFilterCount(result);
                const query = filtersToQuery(result.output);
                this.filterFields = result.output;
                this.params = {
                    project_id: this.project?.id,
                    ...query,
                };
            }

            this.updateProjectsFiles();
        });
    }

    selectedFilterCount(result) {
        this.filterDialogResults = 0;

        result.output.forEach((key) => {

            if (key.value) {
                if (key.value.hasGeometry) {
                    if (key.value.hasGeometry.length) ++this.filterDialogResults;
                } else if ((key.value !== '') && (key.value !== undefined) && (key.value.length)) {
                    ++this.filterDialogResults;
                } else {
                    return;
                }
            }
        });
    }

    getFile(file: ValidatedProjectFile) {
        const fileParts = file.file_id.split('.');
        const extension = fileParts[fileParts.length - 1];
        const name = file.file_name.indexOf(extension) > -1 ?
            file.file_name :
            `${file.file_name}.${extension}`;
        this.actionService.create({
            id: 'SaveFile',
            target: [{ id: file.file_id }],
            loadingMessage: 'Fetching file, please wait...',
            errorMessage: 'Error: file could not be downloaded',
            showError: true,
            name,
            viewModel: this,
        } as SaveFileActionData);
    }

    async toggleLocation(item: ValidatedProjectFile) {
        const feature = await this.ma.getFeature(item, PHOTO_LAYER_ID);
        this.ma.zoom(feature);
    }

    toggleEditing(item: ValidatedProjectFile | ValidatedProjectFile[], edit?: boolean) {
        if (!Array.isArray(item)) {
            item = [item];
        }

        this.dialogs.open({
            viewModel: ProjectFileEdit,
            model: { files: item },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            if (!result.output) {
                for (let i = 0; i < result.output.length; i++) {
                    const j = this.files.indexOf(item[i]);
                    this.files.splice(j, 1);
                    this.files = [...this.files];
                    return;
                }
            }
            for (let i = 0; i < result.output.length; i++) {
                Object.assign(item, result.output);
            }
        });
    }

    getSelectedFiles() {
        this.downloadPending = true;
        return this.actionService.create({
            id: 'ExecuteJob',
            showError: true,
            loadingMessage: 'Generating zip file...',
            name: 'zip',
            data: {
                files: this.selectedFiles.map((f) => ({ file_id: f.file_id, file_name: f.file_name })),
            },
            next: {
                id: 'SaveFile',
                completedMessage: 'File successfully downloaded',
            },
        } as ExecuteJobData)
            .finally(() => {
                this.downloadPending = false;
            });
    }

    updateMapSelection() {
        const result = this.files.filter((file) => !!file.__selected);

        if (result.length) {
            this.ma.select(result, PHOTO_LAYER_ID);
        } else {
            this.ma.clearSelection();
        }
    }

    updateSelectedFiles() {
        this.selectedFiles = this.files.filter((file) => !!file.__selected);
    }

    toggleAll(allSelected: boolean): void {
        if (!this.files) {
            return;
        }
        this.files.forEach((file: ValidatedProjectFile) => file.__selected = !allSelected);
        this.updateSelectedFiles();
        this.updateMapSelection();
    }

    filterByFolder() {
        this.params.program_folder = this.selectedFolder.value;
        this.updateProjectsFiles();
    }

    onFolderExit() {
        //Handle folder exit
        this.selectedFolder = null;
        this.params.program_folder = 'root';
        this.destroyFiles();
        this.updateMapSelection();
        this.updateProjectsFiles();
    }

    onFolderSelect(folder: FileFolder) {
        if (folder) {
            this.files = [];
            this.selectedFolder = folder;
            this.selectedFiles = [];
            this.filterByFolder();
        }
    }

    async addFilesToFolder(folder: string) {
        for (const file of this.selectedFiles) {
            file.program_folder = folder;
            await this.api.projectFiles.patch(file.id, file);
        }
        this.selectedFiles = [];
        this.moveToFolder = '';
        this.updateProjectsFiles();
    }
}
