import { DefaultActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTypes';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { bindable, autoinject, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { BrowserStorage } from '../../../services/util/BrowserStorage';
import { ActiveProgram } from '../../../services/util/ActiveProgram';
import { ActiveProjects } from '../../../services/util/ActiveProjects';
import { ActionService } from '../../../services/actions/ActionService';
import { ExtentChangeResult, MapAdaptor } from '../../../services/util/MapAdaptor';

import { projectFilters } from '../../../types/Project';

import { subscribe } from '../../../util/decorators/subscribe';
import { debounced } from '../../../util/decorators/debounced';

import { ProjectFilter } from './events/ProjectFilter';
import { FilterDialog } from '../../../components/filter-dialog/filter-dialog';
import { Project } from '@wsb_dev/datafi-shared/lib/types/Project';
import { Field } from '@wsb_dev/datafi-shared/lib/types/Field';
import { ValidatedProject } from '../../../types/Project';
import { Filter } from '@wsb_dev/datafi-shared/lib/types';
import { filtersToQuery } from '@wsb_dev/datafi-shared/lib/util/fields/filtersToQuery';
import { queryToFilter } from '@wsb_dev/datafi-shared/lib/util/fields/queryToFilter';
import { StorageItem } from '../../../services/util/_StorageBase';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { Paginated } from '@feathersjs/feathers';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';

export type TProjectSortType = 'pinned' | 'modified_at' | 'project_name';

const log = LogManager.getLogger('dfp:projects');

@subscribe({
    attached: 'mapAttached',
    detached: 'mapDetached',
    events: [
        { eventEmitter: 'ma', event: 'extentchange', fn: 'onExtentChange' },
    ],
})
@subscribe({
    events: [
        { eventEmitter: 'api.projects', event: 'created', fn: 'projectCreated' },
        { eventEmitter: 'api.projects', event: 'removed', fn: 'projectRemoved' },
    ],
})

@subscribe({
    events: [
        { eventEmitter: 'activeProjects', event: 'projectSelectionChanged', fn: 'updateProjectSelection' }],
})

@autoinject
export class ProjectList {
    @bindable projectQuery: any = {
        $sort: { modified_at: -1 },
    };
    @bindable projects: ValidatedProject[] = [];
    @bindable selectedProjects: ValidatedProject[] = [];
    @bindable projectSearch: string;
    @bindable projectId: number;

    @bindable projectsTotal = 0;
    @bindable pageNumber: number;
    @bindable perPage: number;

    @bindable programId: number;
    @bindable surveyId: number;

    @bindable filterMap: boolean;
    @bindable filterDialogResults: number;
    @bindable currentProjectFilters: Filter[];

    sortOptions = [
        { name: 'modified_at', label: 'Modified Date' },
        { name: 'project_name', label: 'Project Name' },
        { name: 'pinned', label: 'Pinned Projects' },
    ]
    sortSelection: TProjectSortType = null;
    pinnedProjects: number[] = [];
    mapSelection: number[] = [];
    fields: Field[];
    @bindable bulkEditMode: boolean;
    loading: boolean;

    constructor(
        public dialogService: DialogService,
        public storage: BrowserStorage,
        public actions: ActionService,
        public program: ActiveProgram,
        public activeProjects: ActiveProjects,
        public router: Router,
        public ea: EventAggregator,
        public ma: MapAdaptor,
        public api: DatafiProAPI,
    ) { }

    async attached() {
        this.filterDialogResults = 0;

        await this.program.load();
        // get the cached program id
        const id = `projectQuery-${this.program.id}`;
        const { data: projectQuery } = await this.storage.get(id)
            .catch((e) => ({ id: id, data: {} } as StorageItem));

        // get sort Selection
        const projectSortSelection = `projectSortSelection-${this.program.id}`;
        await this.storage.get(projectSortSelection).then((result) => {
            this.sortSelection = result?.data;
        }).catch((e) => ({ id: projectSortSelection, data: {} } as StorageItem));

        this.selectedProjects = this.activeProjects.selectedProjects.some((p) => p.program_id !== this.program.id) ?
            [] : this.activeProjects.selectedProjects;

        // get pinned Projects
        const pinnedId = `pinned_projects-${this.program.id}`;
        await this.storage.get(pinnedId).then((result) => {
            this.pinnedProjects = result?.data;
        }).catch((e) => ({ id: pinnedId, data: {} } as StorageItem));

        if (!this.sortSelection) {
            this.sortSelection = 'modified_at';
        }

        if (this.sortSelection === 'modified_at' || this.sortSelection === 'project_name') {
            this.projectQuery.$sort = { [this.sortSelection]: this.sortSelection === 'modified_at' ? -1 : 1 };
        }

        if (projectQuery?.$limit) {
            this.perPage = projectQuery.$limit;
        }

        this.projectQuery = {
            ...projectQuery,
            ...this.program.projectQuery,
            ...this.projectQuery,
            program_id: this.program.id,
        };

        // ensure 'metadata' is included in path.
        this.program.projectSchema.forEach((field) => {
            if (!field.path || !field.path.startsWith('metadata.')) {
                field.path = `metadata.${field.name}`;
            }
        });

        this.currentProjectFilters = queryToFilter([
            ...projectFilters as Filter[],
            ...this.program.projectSchema.filter((f) => f.visibility.filter === true),
        ], [], this.projectQuery);

        this.fields = flattenFields([
            {
                label: 'Status',
                name: 'status',
                type: 'text',
                visibility: { list: true, details: true, filter: true, edit: true },
                options: [{ value: 'Closed', label: 'Closed' }, { value: 'Open', label: 'Open' }],

            }, {
                label: 'Project Name',
                name: 'project_name',
                type: 'text',
                visibility: { list: true, details: true, filter: true, edit: true },
            },
        ]);
    }

    detached() {
        this.mapDetached();
    }

    // event subscribers use arrow syntax
    projectCreated = (project: Project) => {
        if (this.program.id !== project.program_id) {
            return;
        }
        if ((this.api.auth.me.organizationId === project.organizationId) || project.organizationId === 0) {
            this.projects.unshift(project);
        }
    }

    onExtentChange = (eventData: ExtentChangeResult) => {
        this.projectQuery = {
            ...this.projectQuery,
            $modify: { intersect: [eventData.bbox] },
        };
    }

    projectRemoved = (project) => {
        const deletedProjectIndex = this.selectedProjects.findIndex((proj) => (proj.id === project.id ));
        if(deletedProjectIndex >= 0){
            this.selectedProjects.splice(deletedProjectIndex, 1);
        }
        for (let i = 0; i < this.projects.length; i++) {
            if (this.projects[i].id === project.id) {
                this.projects = [
                    ...this.projects.slice(0, i),
                    ...this.projects.slice(i + 1),
                ];
                return;
            }
        }
    }

    //Called anytime the selected project changes
    selectedProjectsChanged(newSelectedProjects: Project[]): void {
        this.selectedProjects = newSelectedProjects;
        this.ma.select(this.selectedProjects, 'projectFeatures', 'id');
    }

    //Only called when a single project is selected on the map
    updateProjectSelection(newSelection: ValidatedProject[]) {
        this.selectedProjects = newSelection;
        newSelection.find((s) => {
            this.pinnedProjects.find((pinid) => {
                if (pinid === s.id) {
                    s._pinned = true;
                }
            });
        });
    }

    bulkEditModeChanged(bulkEditMode: boolean): void {
        this.selectedProjects = [];
    }

    mapAttached() {
        this.onExtentChange(this.ma.getExtent());
        this.updateProjectSelection(this.activeProjects.selectedProjects);
        return;
    }

    mapDetached() {
        this.projectQuery = {
            ...this.projectQuery,
            $modify: undefined,
        };
    }

    filterMapChanged(filterMap: boolean): void {
        if (filterMap) {
            this.mapAttached();
        } else {
            this.mapDetached();
        }
    }

    updateFilters() {
        return this.dialogService.open({
            viewModel: FilterDialog,
            model: {
                filterProperties: this.currentProjectFilters.filter((f) => f.name !== 'program_id'),
            },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }

            const query = filtersToQuery(result.output);

            const id = `projectQuery-${this.program.id}`;
            this.storage.create({ id, data: query });

            if (this.sortSelection === 'modified_at' || this.sortSelection === 'project_name') {
                this.projectQuery = {
                    $sort: { [this.sortSelection]: this.sortSelection === 'modified_at' ? -1 : 1 },
                    ...query,
                    program_id: this.program.id,
                };
            } else {
                this.projectQuery = {
                    ...query,
                    program_id: this.program.id,
                };
            }

            this.currentProjectFilters = result.output;
            this.pageNumber = 1; // show first page results
            this.selectedProjects = [];
            this.updateSelectedFilterCount(this.projectQuery);

        });
    }

    updateSelectedFilterCount(query) {
        this.filterDialogResults = 0;

        Object.entries(query).forEach(([key, value]) => {
            if (key !== 'program_id' && key[0] !== '$' && value !== undefined) {
                if (key === 'metadata') {
                    Object.entries(value).forEach(([metaKey, metaValue]) => {
                        if (metaValue !== undefined && metaValue !== '') {
                            ++this.filterDialogResults;
                        }
                    });
                } else {
                    ++this.filterDialogResults;
                }
            }
        });

        if (query.$modify) {
            if (query.$modify.checkboxQuery) {
                this.filterDialogResults += query.$modify.checkboxQuery.length;
            }
            if (query.$modify.numberQuery) {
                this.filterDialogResults += query.$modify.numberQuery.length;
            }
        }
    }

    // Projects
    @debounced(200)
    async updateProjects(query) {
        this.loading = true;
        this.ea.publish(new ProjectFilter(query));

        if (!query.id) {
            this.updateSelectedFilterCount(query);
        }

        return this.api.projects.find({
            query: {
                ...query,
                program_id: this.program.id,
            },
        }).then((result: any) => {
            this.projects = result.data;
            this.projectsTotal = result.total;
            this.loading = false;
            if (this.pinnedProjects.length) {
                this.getPinnedProjects();
            }
        });
    }

    perPageChanged(perpage: number): void {
        this.projectQuery = {
            ...this.projectQuery,
            $limit: perpage,
            $skip: (this.pageNumber - 1) * this.perPage,
        };
    }

    pageNumberChanged(pageNumber: number) {
        this.projectQuery = {
            ...this.projectQuery,
            $skip: (pageNumber - 1) * this.perPage,
        };
    }

    projectQueryChanged(query) {
        if (!query.program_id || query.program_id !== this.program.id) {
            log.warn('project-list: no program id');
            return;
        }
        this.updateProjects(query);
    }

    projectSearchChanged(searchValue) {
        if (this.pageNumber > 1) {
            this.pageNumber = 1;
        }
        const query = searchValue ? {
            $or: [
                { project_name: { $ilike: `%${searchValue.trim()}%` } },
                { metadata: { $ilike: `%${searchValue.trim()}%` } },
            ],
        } : { $or: undefined };
        this.projectQuery = {
            ...this.projectQuery,
            ...query,
        };
    }

    createAction(action: DefaultActionData) {
        this.actions.create({
            ...action,
            viewModel: this,
        });
    }

    @debounced(200)
    getProjectDetails(project) {
        this.selectedProjects = [project];
        this.activeProjects.select([project.id]); // route changing before project can be selected in list
        this.router.navigateToRoute('project-detail', { projectId: project.id });
    }

    getPinnedProjects() {
        this.api.projects.find({
            query: {
                id: { $in: this.pinnedProjects },
                ...this.projectQuery,
                $skip: 0,
                program_id: this.program.id,
            },
        }).then((pinnedResult: Paginated<ValidatedProject>) => {
            const pinned = (pinnedResult as Paginated<ValidatedProject>).data;
            pinned.forEach((pinnedProject) => {
                const otherProject: ValidatedProject = this.projects.find((project) => pinnedProject.id === project.id);
                const pinId = otherProject ? otherProject.id : pinnedProject.id;

                if (!otherProject && this.sortSelection === 'pinned')  {
                    this.projects.push(pinnedProject); // if pinned project not in current page, push to project list
                }

                if (this.selectedProjects) { // If a map selection is map and matches pins, pin the map selection.
                    const selectedProjectPinned = this.selectedProjects.find((selectedProject) => selectedProject.id === pinId);
                    if (selectedProjectPinned) {
                        selectedProjectPinned._pinned = true;
                    }
                }
            });

            if (this.sortSelection === 'pinned') {
                this.sortPinnedProjects();
            }
        });
    }

    sortSelectionChanged() {
        switch (this.sortSelection) {
        case 'modified_at':
        case 'project_name':
            this.projectQuery = {
                ...this.projectQuery,
                $sort: {
                    [this.sortSelection]: this.sortSelection === 'modified_at' ? -1 : 1,
                },
            };
            break;
        case 'pinned':
            if (this.pinnedProjects.length) {
                this.getPinnedProjects();
            }
            break;
        }
        const projectSortSelection = `projectSortSelection-${this.program.id}`;
        this.storage.create({ id: projectSortSelection, data: this.sortSelection });
    }

    sortPinnedProjects() {
        this.projects.sort((projectA: ValidatedProject, projectB: ValidatedProject) => {
            if (projectA._pinned && !projectB._pinned) {
                return -1;
            }
            if (!projectA._pinned && projectB._pinned) {
                return 1;
            }
            return 0;
        });
    }

    pinProject(project: ValidatedProject, selected?: string) {

        this.selectedProjects?.filter((proj) => {
            if (proj.id === project.id) {
                project._pinned ? proj._pinned = false : proj._pinned = true;
            }
        });

        this.projects?.forEach((proj) => {
            if (proj.id === project.id) {
                project._pinned ? proj._pinned = false : proj._pinned = true;

                if (selected) {
                    project._pinned ? proj._pinned = true : proj._pinned = false;
                }
            }
        });

        if (project._pinned) {
            this.pinnedProjects.push(project.id);
        } else {
            this.pinnedProjects = this.pinnedProjects.filter((id) => project.id !== id);
        }

        if (this.sortSelection === 'pinned') {
            this.getPinnedProjects();
        }

        this.storage.create({ id: `pinned_projects-${this.program.id}`, data: this.pinnedProjects.length ? [...this.pinnedProjects] : [] });
    }
}
